import React from "react";

export function Pager(props) {
  const totalPages = Math.ceil(props.count / props.limit);
  const [currentPage, setCurrentPage] = React.useState(1);

  function setFirstPage() {
    if (currentPage !== 1) {
      setCurrentPage(1);
      props.setSkip(0);
    }
  }

  function setLastPage() {
    if (currentPage !== totalPages) {
      setCurrentPage(totalPages || 1);
      props.setSkip(((totalPages - 1) * props.limit) || 1);
    }
  }

  function decrementPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      props.setSkip((currentPage - 2) * props.limit);
    }
  }

  function incrementPage() {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      props.setSkip(currentPage * props.limit);
    }
  }

  return (
    <div className="row pager">
      <button className="pager-button" onClick={setFirstPage}>{"<<"}</button>
      <button className="pager-button" onClick={decrementPage}>{"<"}</button>
      <div className="pager-current">{currentPage}</div>
      <button className="pager-button" onClick={incrementPage}>{">"}</button>
      <button className="pager-button" onClick={setLastPage}>{">>"}</button>
    </div>
  );
}
