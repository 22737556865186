import React from "react";
import axios from "../request";
import addMonths from "date-fns/addMonths";
import { format } from "date-fns";
import { emailPattern } from "../utils/email-pattern";
import { User } from "../components/user";
import { UserSearch } from "../components/search";
import { Pager } from "../components/pager";
import { PassSelect } from "../components/pass-select";
import { Navigation } from "../components/navigation";

export function Users(props) {
  const initialState = {
    email: "",
    barcode: "",
    name: "",
    birthDate: "",
    accept: false,
    remaining: 0,
    passValid: "",
    passType: "",
    key: ""
  }

  const [{ email, barcode, name, birthDate, accept, key }, setState] = React.useState(initialState);
  const [remaining, setRemaining] = React.useState(0);
  const [passValid, setPassValid] = React.useState("");
  const [passType, setPassType] = React.useState("");

  function handleChange(event) {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  function remainingChangeHandler(event) {
    let newVal = parseInt(event.target.value);
    if (!newVal || newVal < 0) newVal = 0;
    setRemaining(newVal);
  }

  function reset() {
    setState({ ...initialState });
    setRemaining(initialState.remaining);
    setPassValid(initialState.passValid);
    setPassType(initialState.passType);
  }

  function addUser() {
    return axios.post('/users', {
      email,
      barcode,
      name,
      birthDate,
      accept,
      remaining,
      passValid,
      passType,
      key,
      checkedIn: true
    })
      .then(() => {
        reset();
      })
      .then(props.getUsers)
      .then(props.getOccupation)
      .catch(console.error);
  }

  function checkoutAll() {
    return axios.post('/checkout-all', {})
      .then(() => {
        reset();
      })
      .catch(console.error);
  }

  function modifyDate(mod) {
    const newValid = passValid ? addMonths(new Date(passValid), mod) : addMonths(new Date(), mod);
    return axios.post('/pass', {
      barcode,
      email,
      time: newValid
    })
      .then(() => setPassValid(() => newValid))
      .catch(console.error);
  }

  function changePassValid(event) {
    const newDate = new Date(event.target.value);
    const newValid = format(newDate, "yyyy-MM-dd");
    return setPassValid(newValid);
  }

  function changePassType(event) {
    return setPassType(event.target.value);
  }

  function acceptChangeHandler() {
    setState(prevState => ({ ...prevState, accept: !accept }));
  }

  return (
    <div>
      <div>
        <button onClick={props.logoutHandler}>Kijelentkezés</button>
        <Navigation />
        <div className="page-header">
          <h1>Vendégek (belépve: {props.occupation})</h1>
        </div>
        <div>
          <UserSearch setSearch={props.setSearch} />
          <div className="row">
            <span className="row-item">Név</span>
            <span className="row-item button-item" />
            <span className="row-item narrow-column">Kulcs</span>
            <span className="row-item">Email</span>
            <span className="row-item">Vonalkód</span>
            <span className="row-item">Születési idő</span>
            <span className="row-item checkbox-column">Nyilatkozat</span>
            <span className="row-item">Fennmaradó alkalmak</span>
            <span className="row-item">Bérlet érvényesség</span>
            <span className="row-item wide-column">Bérlet típus</span>
            <span className="row-item button-item" />
          </div>
          {
            props.users && props.users.map(user => {
              return (
                <User language={props.language} user={user} key={user._id} getOccupation={props.getOccupation} />
              );
            })
          }
          <Pager limit={props.limit} skip={props.skip} setSkip={props.setSkip} count={props.count} />
          <div className="add-new">
            <div className="row">
              <div className="row-item">
                <input name="name" value={name} onChange={handleChange} />
              </div>
              <span className="row-item button-item" />
              <div className="row-item narrow-column">
                <input name="key" value={key} onChange={handleChange} />
              </div>
              <div className="row-item">
                <input name="email" value={email} onChange={handleChange} type="email" inputMode="email" pattern={emailPattern} />
              </div>
              <div className="row-item">
                <input name="barcode" value={barcode} onChange={handleChange} />
              </div>
              <div className="row-item">
                <input name="birthDate" type="date" value={birthDate} onChange={handleChange} />
              </div>
              <div className="row-item checkbox-column">
                <input name="accept" type="checkbox" checked={accept} value={accept} onChange={acceptChangeHandler} />
              </div>
              <div className="row-item">
                {false && <button onClick={() => setRemaining(remaining + 10)}>+10</button>}
                {false && <button onClick={() => setRemaining(remaining + 1)}>+1</button>}
                <input value={remaining} onChange={remainingChangeHandler} />
                {false && <button onClick={() => setRemaining(remaining - 1)}>-1</button>}
                {false && <button onClick={() => setRemaining(remaining - 10)}>-10</button>}
              </div>
              <div className="row-item">
                {false && <button onClick={() => modifyDate(1)}>+1</button>}
                <input type="date" value={passValid ? format(new Date(passValid), "yyyy-MM-dd") : ""} onChange={changePassValid} />
                {false && <button onClick={() => modifyDate(-1)}>-1</button>}
              </div>
              <div className="row-item wide-column">
                <PassSelect language={props.language} passType={passType} changeHandler={changePassType} />
              </div>
              <div className="row-item button-item">
                <button onClick={addUser}>+ Új vendég</button>
              </div>
            </div>
          </div>
        </div>
        <button style={{ marginTop: "50px" }} onClick={checkoutAll}>Összes vendég kiléptetése</button>
      </div>
    </div>
  );
}
