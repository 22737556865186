import React from "react";
import axios from "../request";

export function Product(props) {
  const initialState = {
    name: props.product.name,
    barcode: props.product.barcode,
    price: props.product.price,
    stock: props.product.stock,
    soldAmount: 0
  }

  const [{ barcode, name, price, stock, soldAmount }, setState] = React.useState(initialState);

  function handleChange(event) {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  function resetSoldAmount() {
    setState(prevState => ({ ...prevState, soldAmount: initialState.soldAmount }));
  }

  function handleStockChange(value) {
    if (value >= 0) {
      setState(prevState => ({...prevState, stock: value}));
    } else {
      setState(prevState => ({ ...prevState, stock: 0 }));
    }
  }

  function handleNumberChange(event) {
    const { name, value } = event.target;
    if (parseInt(value) >= 0) {
      setState(prevState => ({...prevState, [name]: parseInt(value)}));
    } else {
      setState(prevState => ({ ...prevState, [name]: 0 }));
    }
  }

  function saveProduct() {
    return axios.post("/product", {
      barcode,
      name,
      stock,
      price
    })
      .then(props.getProducts)
      .catch(console.error);
  }

  function saleHandler() {
    if (soldAmount <= stock) {
      return axios.post("/product", {
        barcode,
        name,
        stock,
        price,
        soldAmount
      })
        .then(resetSoldAmount)
        .then(() => {
          setState(prevState => ({ ...prevState, stock: stock - soldAmount }));
        })
        .then(props.getProducts)
        .catch(console.error);
    }
  }

  function modifyStock(mod) {
    let newStock = stock ? stock + mod : mod;
    if (!newStock || newStock < 0) newStock = 0;
    return axios.post("/product", {
      barcode,
      name,
      price,
      stock: newStock
    })
      .then(() => handleStockChange(newStock))
      .catch(console.error);
  }

  return (
    <div className="row product-row">
      <div className="row-item">
        <input name="name" value={name} onChange={handleChange} />
      </div>
      <div className="row-item">
        <input name="barcode" value={barcode} onChange={handleChange} />
      </div>
      <div className="row-item">
        <input name="price" value={price} onChange={handleNumberChange} />
      </div>
      <div className="row-item">
        {false && <button onClick={() => modifyStock(10)}>+10</button>}
        {false && <button onClick={() => modifyStock(1)}>+1</button>}
        <input name="stock" value={stock} onChange={handleNumberChange} />
        {false && <button onClick={() => modifyStock(-1)}>-1</button>}
        {false && <button onClick={() => modifyStock(-10)}>-10</button>}
      </div>
      <div className="row-item">
        <button onClick={saveProduct}>Mentés</button>
      </div>
      <div className="row-item">
        <input name="soldAmount" value={soldAmount} onChange={handleNumberChange} />
        <button onClick={saleHandler}>Eladás</button>
      </div>
    </div>
  );
}
