import React from "react";
import axios from "../request";
import { emailPattern } from "../utils/email-pattern";

export function Login(props) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  function emailChangeHandler(event) {
    setEmail(event.target.value);
  }

  function passwordChangeHandler(event) {
    setPassword(event.target.value);
  }

  async function loginHandler() {
    const response = await axios.post('/login', {
      email,
      password
    });
    localStorage.setItem("token", response.data.token);
    props.setToken(response.data.token);
  }

  return (
    <div>
      <div>
        <div>
          <label htmlFor="email">
            Email:
            <input id="email" onChange={emailChangeHandler} type="email" inputMode="email" pattern={emailPattern}/>
          </label>
        </div>
        <div>
          <label htmlFor="password">
            Jelszó:
            <input id="password" onChange={passwordChangeHandler} type="password"/>
          </label>
        </div>
        <button type="submit" onClick={loginHandler}>Bejelentkezés</button>
      </div>
    </div>
  );
}
