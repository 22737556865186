//TODO at this point, this should probably come from the db through the api
export default {
  hu: {
    tickets: [
      {
        name: "Felnőtt napijegy, csúcsidő",
        price: "2200 HUF",
        details: "Hétköznap 16:00 után és hétvégén egész nap"
      },
      {
        name: "Felnőtt napijegy, mellékidő",
        price: "2000 HUF",
        details: "Hétköznap 16:00 előtt"
      },
      {
        name: "Felnőtt napijegy, délelőtt",
        price: "1700 HUF",
        details: "Hétköznap 12:00 előtt"
      },
      {
        name: "Diákjegy, csúcsidő",
        price: "1700 HUF",
        details: "Hétköznap 16:00 után és hétvégén egész nap, diák igazolvánnyal, 26 éves kor alatt"
      },
      {
        name: "Diákjegy, mellékidő",
        price: "1400 HUF",
        details: "Hétköznap 16:00 előtt, diák igazolvánnyal, 26 éves kor alatt"
      },
      {
        name: "Diákjegy, délelőtt",
        price: "1200 HUF",
        details: "Hétköznap 12:00 előtt, diák igazolvánnyal, 26 éves kor alatt"
      },
      {
        name: "Gyerekjegy",
        price: "1200 HUF",
        details: "14 éves kor alatt"
      },
      {
        name: "Flow-jegy",
        price: "1200 HUF",
        details: "Zárás előtt 1 órával"
      },
      {
        name: "Építős jegy",
        price: "900 HUF",
        details: "Építés alatt (nem minden falrész elérhető)"
      }
    ],
    passes: [
      {
        name: "Felnőtt 10 alkalmas bérlet, csúcsidő",
        price: "20000 HUF"
      },
      {
        name: "Felnőtt 10 alkalmas bérlet, mellékidő",
        price: "18000 HUF"
      },
      {
        name: "Felnőtt 10 alkalmas bérlet, délelőtt",
        price: "15500 HUF"
      },
      {
        name: "Felnőtt havi korlátlan bérlet",
        price: "17500 HUF"
      },
      {
        name: "Felnőtt negyedéves korlátlan bérlet",
        price: "47000 HUF"
      },
      {
        name: "Felnőtt féléves korlátlan bérlet",
        price: "84000 HUF"
      },
      {
        name: "Felnőtt éves korlátlan bérlet",
        price: "120000 HUF"
      },
      {
        name: "Diák 10 alkalmas bérlet, csúcsidő",
        price: "15000 HUF"
      },
      {
        name: "Diák 10 alkalmas bérlet, mellékidő",
        price: "12500 HUF"
      },
      {
        name: "Diák 10 alkalmas bérlet, délelőtt",
        price: "11000 HUF"
      },
      {
        name: "Diák havi korlátlan bérlet",
        price: "13500 HUF"
      },
      {
        name: "Diák negyedéves korlátlan bérlet",
        price: "35000 HUF"
      },
      {
        name: "Diák féléves korlátlan bérlet",
        price: "64000 HUF"
      },
      {
        name: "Diák éves korlátlan bérlet",
        price: "95000 HUF"
      },
      {
        name: "Gyerek 10 alkalmas bérlet",
        price: "10500 HUF"
      },
      {
        name: "Gyerek havi korlátlan bérlet",
        price: "9500 HUF"
      },
      {
        name: "Gyerek negyedéves korlátlan bérlet",
        price: "25000 HUF"
      },
      {
        name: "Gyerek féléves korlátlan bérlet",
        price: "45000 HUF"
      },
      {
        name: "Gyerek éves korlátlan bérlet",
        price: "75000 HUF"
      }
    ]
  },
  en: {
    tickets: [
      {
        name: "Adult daily pass, peak hours",
        price: "2200 HUF",
        details: "From 16:00 on weekdays and all day on weekends"
      },
      {
        name: "Adult daily pass, early afternoon",
        price: "2000 HUF",
        details: "From 12:00 to 16:00 on weekdays"
      },
      {
        name: "Adult daily pass, before noon",
        price: "1700 HUF",
        details: "Before 12:00 on weekdays"
      },
      {
        name: "Student daily pass, peak hours",
        price: "1700 HUF",
        details: "From 16:00 on weekdays and all day on weekends, with student card, 26 years old and below"
      },
      {
        name: "Student daily pass, early afternoon",
        price: "1400 HUF",
        details: "From 12:00 to 16:00 on weekdays, with student card, 26 years old and below"
      },
      {
        name: "Student daily pass, before noon",
        price: "1200 HUF",
        details: "Before 12:00 on weekdays, with student card, 26 years old and below"
      },
      {
        name: "Kids daily pass",
        price: "1200 HUF",
        details: "14 years old and below"
      },
      {
        name: "Flow-pass",
        price: "1200 HUF",
        details: "One hour before closing"
      },
      {
        name: "Route-setting pass",
        price: "900 HUF",
        details: "During route-setting (not all sections are available)"
      }
    ],
    passes: [
      {
        name: "Adult 10 day pass, peak hours",
        price: "20000 HUF"
      },
      {
        name: "Adult 10 day pass, early afternoon",
        price: "18000 HUF"
      },
      {
        name: "Adult 10 day pass, before noon",
        price: "15500 HUF"
      },
      {
        name: "Adult monthly pass",
        price: "17500 HUF"
      },
      {
        name: "Adult quarterly pass",
        price: "47000 HUF"
      },
      {
        name: "Adult 6 month pass",
        price: "84000 HUF"
      },
      {
        name: "Adult yearly pass",
        price: "120000 HUF"
      },
      {
        name: "Student 10 day pass, peak hours",
        price: "15000 HUF"
      },
      {
        name: "Student 10 day pass, early afternoon",
        price: "12500 HUF"
      },
      {
        name: "Student 10 day pass, before noon",
        price: "11000 HUF"
      },
      {
        name: "Student monthly pass",
        price: "13500 HUF"
      },
      {
        name: "Student quarterly pass",
        price: "35000 HUF"
      },
      {
        name: "Student 6 month pass",
        price: "64000 HUF"
      },
      {
        name: "Student yearly pass",
        price: "95000 HUF"
      },
      {
        name: "Kids 10 day pass",
        price: "10500 HUF"
      },
      {
        name: "Kids monthly pass",
        price: "9500 HUF"
      },
      {
        name: "Kids quarterly pass",
        price: "25000 HUF"
      },
      {
        name: "Kids 6 month pass",
        price: "45000 HUF"
      },
      {
        name: "Kids yearly pass",
        price: "75000 HUF"
      }
    ]
  }
}
