import React from "react";
import { debounce } from "../utils/debounce";

export function UserSearch(props) {
  const initialState = {
    email: "",
    name: "",
    barcode: "",
    checkedIn: false
  }

  const [{ email, name, barcode, checkedIn }, setState] = React.useState(initialState);

  function handleChange(event) {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  function performSearch() {
    props.setSearch({
      email,
      barcode,
      name,
      checkedIn: checkedIn || undefined
    });
  }

  function resetSearch() {
    setState({ ...initialState });
    props.setSearch({});
  }

  React.useEffect(() => {
    performSearch();
  }, [barcode]);

  function checkedInChangeHandler() {
    setState(prevState => ({ ...prevState, checkedIn: !checkedIn }));
  }

  return (
    <div className="row searchbar">
      <label htmlFor="name-search" className="row-item">
        Név
        <input value={name} name="name" id="name-search" type="text" onChange={handleChange} />
      </label>
      <label htmlFor="email-search" className="row-item">
        Email
        <input value={email} name="email" id="email-search" type="text" onChange={handleChange} />
      </label>
      <label htmlFor="barcode-search" className="row-item">
        Vonalkód
        <input value={barcode} name="barcode" id="barcode-search" type="text" onChange={handleChange} />
      </label>
      <label htmlFor="checkedin-search" className="row-item">
        Belépve
        <input id="checkedin-search" name="checkedIn" type="checkbox" checked={checkedIn} value={checkedIn} onChange={checkedInChangeHandler} />
      </label>
      <button onClick={performSearch}>Keresés</button>
      <button onClick={resetSearch}>Reset</button>
    </div>
  );
}

export function ProductSearch(props) {
  const initialState = {
    name: "",
    barcode: ""
  }

  const [{ name, barcode }, setState] = React.useState(initialState);

  function handleChange(event) {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  function performSearch() {
    props.setSearch({
      barcode,
      name
    });
  }

  React.useEffect(() => {
    performSearch();
  }, [barcode]);

  function resetSearch() {
    setState({ ...initialState });
    props.setSearch({});
  }

  return (
    <div className="row searchbar">
      <label htmlFor="name-search" className="row-item">
        Név
        <input id="name-search" name="name" type="text" onChange={handleChange} />
      </label>
      <label htmlFor="barcode-search" className="row-item">
        Vonalkód
        <input id="barcode-search" name="barcode" type="text" onChange={handleChange} />
      </label>
      <button onClick={performSearch}>Keresés</button>
      <button onClick={resetSearch}>Reset</button>
    </div>
  );
}

export function LogSearch(props) {
  const initialState = {
    email: "",
    name: "",
    barcode: ""
  }

  const [{ email, name, barcode }, setState] = React.useState(initialState);

  function handleChange(event) {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  function performSearch() {
    props.setSearch({
      email,
      barcode,
      name
    });
  }

  function resetSearch() {
    setState({ ...initialState });
    props.setSearch({});
  }

  return (
    <div className="row searchbar">
      <label htmlFor="name-search" className="row-item">
        Név
        <input value={name} name="name" id="name-search" type="text" onChange={handleChange} />
      </label>
      <label htmlFor="email-search" className="row-item">
        Email
        <input value={email} name="email" id="email-search" type="text" onChange={handleChange} />
      </label>
      <label htmlFor="barcode-search" className="row-item">
        Vonalkód
        <input value={barcode} name="barcode" id="barcode-search" type="text" onChange={handleChange} />
      </label>
      <button onClick={performSearch}>Keresés</button>
      <button onClick={resetSearch}>Reset</button>
    </div>
  );
}
