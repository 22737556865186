import axios from "axios";

const api = axios.create({
  baseURL: `https://admin.fless.hu/api/v1/admin`
});

const token = localStorage.getItem("token");
if (token) {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export default api;
