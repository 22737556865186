import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Navigation } from "../components/navigation";
import axios from "../request";
import {compare} from "../utils/utils";

export function OccupationLogs(props) {
  const initialState = {
    date: new Date(),
    logs: []
  }

  const [{ date, logs }, setState] = React.useState(initialState);

  function handleChange(event) {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  function dateChange(date) {
    setState(prevState => ({ ...prevState, date }));
  }

  function reset() {
    setState({ ...initialState });
  }

  function getOccupationLogs() {
    return axios.post(`/user/logs/occupation`, { date })
      .then(response => {
        const sorter = compare("day");
        const sortedRecords = response.data.occupation.map((record) => {
          record.day = record._id.day
          return record
        }).sort(sorter);
        setState(prevState => ({ ...prevState, logs: sortedRecords }));
      })
      .catch(console.error);
  }

  React.useEffect(() => {
    getOccupationLogs();
  }, [date]);

  return (
    <div>
      <div>
        <button onClick={props.logoutHandler}>Kijelentkezés</button>
        <Navigation />
        <div className="page-header">
          <h1>Vendégek Száma</h1>
        </div>
        <DatePicker
          selected={date}
          onChange={dateChange}
          dateFormat="yyyy.MM"
          showMonthYearPicker />
        <div className="occupation-table">
          <div />
          <div className="row occupation-row">
            <span className="row-item">Nap</span>
            <span className="row-item">Vendégek Száma</span>
            <span className="row-item">Hónap</span>
            <span className="row-item">Év</span>
          </div>
          {
            logs && logs.map(log => {
              return (
                <div key={log._id.day} className="row user-log-row occupation-row">
                  <div className="row-item">
                    <div>{log._id.day || "-"}</div>
                  </div>
                  <div className="row-item">
                    <div><strong>{log.count || "-"}</strong></div>
                  </div>
                  <div className="row-item">
                    <div>{log._id.month || "-"}</div>
                  </div>
                  <div className="row-item">
                    <div>{log._id.year || "-"}</div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
}
