import passTypes from "../utils/passTypes";
import React from "react";

export function PassSelect(props) {
  return (
    <select className="pass-select" name="passType" value={props.passType} onChange={props.changeHandler}>
      <option value={""}>Bérlet típus</option>
      {
        passTypes[props.language].passes.map((pass) => {
          return <option key={pass.name} value={pass.name}>{pass.name}</option>
        })
      }
    </select>
  );
}
