import React from "react";
import axios from "../request";
import { Product } from "../components/product";
import { ProductSearch } from "../components/search";
import { Pager } from "../components/pager";
import { Navigation } from "../components/navigation";

export function Products(props) {
  const initialState = {
    name: "",
    barcode: "",
    price: 0,
    stock: 0
  }

  const [{ barcode, name, price, stock }, setState] = React.useState(initialState);

  function handleChange(event) {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  function reset() {
    setState({ ...initialState });
  }

  function modifyStock(mod) {
    let newStock = stock ? stock + mod : mod;
    if (!newStock || newStock < 0) newStock = 0;
    setState(prevState => ({ ...prevState, stock: newStock }));
  }

  function handleNumberChange(event) {
    const { name, value } = event.target;
    if (parseInt(value) >= 0) {
      setState(prevState => ({...prevState, [name]: parseInt(value)}));
    } else {
      setState(prevState => ({ ...prevState, [name]: 0 }));
    }
  }

  function addProduct() {
    axios.post('/products', {
      barcode,
      name,
      price,
      stock,
    })
      .then(() => {
        reset();
      })
      .then(props.getProducts)
      .catch(console.error);
  }

  return (
    <div>
      <div>
        <button onClick={props.logoutHandler}>Kijelentkezés</button>
        <Navigation />
        <div className="page-header">
          <h1>Termékek</h1>
        </div>
        <div>
          <ProductSearch setSearch={props.setSearch} />
          <div className="row">
            <span className="row-item">Név</span>
            <span className="row-item">Vonalkód</span>
            <span className="row-item">Ár</span>
            <span className="row-item">Készlet</span>
            <span className="row-item" />
            <span className="row-item" />
          </div>
          {
            props.products && props.products.map(product => {
              return (
                <Product product={product} key={product.name} getProducts={props.getProducts} />
              );
            })
          }
          <Pager limit={props.limit} skip={props.skip} setSkip={props.setSkip} count={props.count} />
          <div className="add-new">
            <div className="row">
              <div className="row-item">
                <input name="name" value={name} onChange={handleChange} />
              </div>
              <div className="row-item">
                <input name="barcode" value={barcode} onChange={handleChange} />
              </div>
              <div className="row-item">
                <input name="price" value={price} onChange={handleNumberChange} />
              </div>
              <div className="row-item">
                {false && <button onClick={() => modifyStock(10)}>+10</button>}
                {false && <button onClick={() => modifyStock(1)}>+1</button>}
                <input name="stock" value={stock} onChange={handleNumberChange} />
                {false && <button onClick={() => modifyStock(-1)}>-1</button>}
                {false && <button onClick={() => modifyStock(-10)}>-10</button>}
              </div>
              <div className="row-item">
                <button onClick={addProduct}>+ Új termék</button>
              </div>
              <span className="row-item" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
