import React from "react";
import axios from "../request";
import addMonths from "date-fns/addMonths";
import subDays from "date-fns/subDays";
import { format, compareDesc } from "date-fns";
import { emailPattern } from "../utils/email-pattern";
import { PassSelect } from "./pass-select";
import differenceInYears from "../utils/difference-in-years";
import { debounce } from "../utils/debounce";

export function User(props) {
  const initialState = {
    key: props.user.key
  }

  const [{ key }, setState] = React.useState(initialState);

  function handleChange(event) {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  //TODO clean this up like the other states
  const [email, setEmail] = React.useState(props.user.email);
  const [barcode, setBarcode] = React.useState(props.user.barcode);
  const [name, setName] = React.useState(props.user.name);
  const [accept, setAccept] = React.useState(props.user.accept);
  const [birthDate, setBirthDate] = React.useState(props.user.birthDate);
  const [remaining, setRemaining] = React.useState(props.user.pass.remaining);
  const [passValid, setPassValid] = React.useState(props.user.pass.passValid);
  const initialColour = compareDesc(new Date(), new Date(props.user.pass.passValid)) === 1 ? "green" : "red";
  const [passColour, setPassColour] = React.useState(initialColour);
  const [checkedIn, setCheckedIn] = React.useState(props.user.checkedIn);
  const [passType, setPassType] = React.useState(props.user.pass.type || "");
  const [ageColour, setAgeColour] = React.useState("black")

  function emailChangeHandler(event) {
    setEmail(event.target.value);
  }

  function barcodeChangeHandler(event) {
    setBarcode(event.target.value);
  }

  function nameChangeHandler(event) {
    setName(event.target.value);
  }

  function remainingChangeHandler(event) {
    let newVal = parseInt(event.target.value);
    if (!newVal || newVal < 0) newVal = 0;
    setRemaining(newVal);
  }

  function modifyRemaining(mod) {
    let newRemaining = remaining ? remaining + mod : mod;
    if (!newRemaining || newRemaining < 0) newRemaining = 0;
    return axios.post('/pass', {
      barcode,
      email,
      amount: newRemaining
    })
      .then(() => setRemaining(newRemaining))
      .catch(console.error);
  }

  function modifyDate(mod) {
    const newValid = passValid ? addMonths(new Date(passValid), mod) : addMonths(new Date(), mod);
    return axios.post('/pass', {
      barcode,
      email,
      time: newValid
    })
      .then(() => setPassValid(newValid))
      .catch(console.error);
  }

  function updateOccupation(value) {
    return axios.post('/occupation', {
      value,
      email
    })
      .then(props.getOccupation)
      .catch(console.error);
  }

  function acceptChangeHandler() {
    const newValue = !accept;
    setAccept(newValue);
    saveUser({ newAccept: newValue });
  }

  function saveUser({ newCheckedIn, newPass, newRemaining, newPassType, newAccept, newBirthDate }) {
    const checkedInValue = newCheckedIn === undefined ? checkedIn : newCheckedIn;
    const passValidValue = newPass === undefined ? passValid : newPass;
    const remainingValue = newRemaining === undefined ? remaining : newRemaining;
    const passTypeValue = newPassType === undefined ? passType : newPassType;
    const acceptValue = newAccept === undefined ? accept : newAccept;
    const birthDateValue = newBirthDate === undefined ? birthDate : newBirthDate;
    return axios.post("/user", {
      _id: props.user._id,
      barcode,
      name,
      email,
      birthDate: birthDateValue,
      accept: acceptValue,
      checkedIn: checkedInValue,
      key,
      pass: {
        passValid: passValidValue,
        remaining: remainingValue || 0,
        type: passTypeValue
      }
    })
      .catch(console.error);
  }

  function toggleCheckedIn() {
    const newValue = !checkedIn;
    setCheckedIn(() => newValue);
    let newRemaining = remaining;
    if (remaining >= 1 && newValue === true) {
      newRemaining = remaining - 1;
      setRemaining(() => newRemaining);
    }
    return saveUser({ newCheckedIn: newValue, newRemaining })
      .then(() => {
        const mod = newValue ? 1 : -1;
        return updateOccupation(mod)
      });
  }

  const debouncedCheckIn = debounce(toggleCheckedIn, 400)

  function changePassValid(event) {
    const newDate = new Date(event.target.value);
    const newValid = format(newDate, "yyyy-MM-dd");
    setPassValid(newValid);
    setPassColour(compareDesc(subDays(new Date(), 1), newDate) >= 0 ? "green" : "red");
    return saveUser({ newPass: newDate });
  }

  function birthDateChangeHandler(event) {
    const newDate = new Date(event.target.value);
    setBirthDate(newDate);
    return saveUser({ newBirthDate: newDate });
  }

  function changePassType(event) {
    const newPassType = event.target.value
    setPassType(newPassType);
    return saveUser({ newPassType });
  }

  React.useEffect(() => {
    if (birthDate) {
      const age = differenceInYears(new Date(), new Date(birthDate));
      if (age < 14) {
        return setAgeColour("yellow");
      } else if (age >= 14 && age < 18) {
        return setAgeColour("red");
      } else if (age >= 18 && age < 26) {
        return setAgeColour("blue");
      }
    }
  }, [birthDate]);

  React.useEffect(() => {
    if (passValid) {
      setPassColour(compareDesc(subDays(new Date(), 1), new Date(passValid)) >= 0 ? "green" : "red");
    }
  }, [passValid]);

  return (
    <div className="row user-list-row" key={props.user._id}>
      <div className="row-item">
        <input disabled value={name} onChange={nameChangeHandler} />
      </div>
      <div className="row-item button-item">
        {!checkedIn && <button onClick={debouncedCheckIn}>Beléptetés</button>}
        {checkedIn && <button onClick={debouncedCheckIn}>Kiléptetés</button>}
      </div>
      <div className="row-item narrow-column">
        <input name="key" value={key} onChange={handleChange} />
      </div>
      <div className="row-item">
        <input disabled value={email} onChange={emailChangeHandler} type="email" inputMode="email" pattern={emailPattern} />
      </div>
      <div className="row-item">
        <input value={barcode} onChange={barcodeChangeHandler} />
      </div>
      <div className="row-item">
        <input className={ageColour} type="date" value={birthDate ? format(new Date(birthDate), "yyyy-MM-dd") : ""} onChange={birthDateChangeHandler} />
      </div>
      <div className="row-item checkbox-column">
        <input type="checkbox" checked={accept} onChange={acceptChangeHandler} />
      </div>
      <div className="row-item">
        {false && <button onClick={() => modifyRemaining(10)}>+10</button>}
        {false && <button onClick={() => modifyRemaining(1)}>+1</button>}
        <input value={remaining} onChange={remainingChangeHandler} />
        {false && <button onClick={() => modifyRemaining(-1)}>-1</button>}
        {false && <button onClick={() => modifyRemaining(-10)}>-10</button>}
      </div>
      <div className="row-item">
        {false && <button onClick={() => modifyDate(1)}>+1</button>}
        <input type="date" value={passValid ? format(new Date(passValid), "yyyy-MM-dd") : ""} className={passColour} onChange={changePassValid} />
        {false && <button onClick={() => modifyDate(-1)}>-1</button>}
      </div>
      <div className="row-item wide-column">
        <PassSelect language={props.language} passType={passType} changeHandler={changePassType} />
      </div>
      <div className="row-item button-item">
        <button onClick={saveUser}>Mentés</button>
      </div>
    </div>
  );
}
