import React from "react";
import { Link } from "react-router-dom";

export function Navigation() {
  return (
    <div className="page-header">
      <Link to="/products">{"Termékek"}</Link>
      <Link to="/users">{"Vendégek"}</Link>
      <Link to="/logs">{"Logok"}</Link>
      <Link to="/occupation">{"Vendégek Száma"}</Link>
    </div>
  );
}
