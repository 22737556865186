import React from "react";
import { format } from "date-fns";
import { LogSearch } from "../components/search";
import { Pager } from "../components/pager";
import { Navigation } from "../components/navigation";
import axios from "../request";

export function UserLogs(props) {
  const initialState = {
    search: {},
    logs: [],
    logCount: 0,
    skip: 0,
    limit: 25
  }

  const [{ logs, logCount, skip, limit, search }, setState] = React.useState(initialState);

  function handleChange(event) {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  function reset() {
    setState({ ...initialState });
  }

  function setSearch(value) {
    setState(prevState => ({ ...prevState, search: value }));
  }

  function setSkip(value) {
    setState(prevState => ({ ...prevState, skip: value }));
  }

  function getLogs() {
    return axios.post(`/user/logs/${limit}/${skip}`, search)
      .then(response => {
        setState(prevState => ({ ...prevState, logs: response.data.logs, logCount: response.data.count }));
      })
      .catch(console.error);
  }

  React.useEffect(() => {
    getLogs();
  }, [search, skip, limit]);

  return (
    <div>
      <div>
        <button onClick={props.logoutHandler}>Kijelentkezés</button>
        <Navigation />
        <div className="page-header">
          <h1>Logok</h1>
        </div>
        <div>
          <LogSearch setSearch={setSearch} />
          <div className="row">
            <span className="row-item wide-column">Dátum</span>
            <span className="row-item">Név</span>
            <span className="row-item">Email</span>
            <span className="row-item">Vonalkód</span>
            <span className="row-item">Fennmaradó alkalmak</span>
            <span className="row-item wide-column">Bérlet típus</span>
            <span className="row-item">Bérlet érvényesség</span>
            <span className="row-item">Log típus</span>
          </div>
          {
            logs && logs.map(log => {
              return (
                <div key={log.date} className="row user-log-row">
                  <div className="row-item wide-column">
                    <div>{format(new Date(log.date), "yyyy-MM-dd HH:mm:ss")}</div>
                  </div>
                  <div className="row-item">
                    <div>{log.name || "-"}</div>
                  </div>
                  <div className="row-item">
                    <div>{log.email || "-"}</div>
                  </div>
                  <div className="row-item">
                    <div>{log.barcode || "-"}</div>
                  </div>
                  <div className="row-item">
                    <div>{log.passRemaining || "-"}</div>
                  </div>
                  <div className="row-item wide-column">
                    <div>{log.passType || "-"}</div>
                  </div>
                  <div className="row-item">
                    <div>{log.passValid ? format(new Date(log.passValid), "yyyy-MM-dd") : "-"}</div>
                  </div>
                  <div className="row-item">
                    <div>{log.event}</div>
                  </div>
                </div>
              );
            })
          }
          <Pager limit={limit} skip={skip} setSkip={setSkip} count={logCount} />
        </div>
      </div>
    </div>
  );
}
