import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import "./App.css";
import axios from "./request";
import { Users } from "./routes/users";
import { Products } from "./routes/products";
import { Login } from "./routes/login";
import { UserLogs } from "./routes/user-logs";
import { OccupationLogs } from "./routes/occupation-logs";

function App() {
  //TODO clean this up like the other states
  //TODO add english language support like on the frontend
  const [users, setUsers] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [limit, setLimit] = React.useState(25);
  const [skip, setSkip] = React.useState(0);
  const [userCount, setUserCount] = React.useState(0);
  const [productLimit, setProductLimit] = React.useState(25);
  const [productSkip, setProductSkip] = React.useState(0);
  const [productCount, setProductCount] = React.useState(0);
  const [token, setToken] = React.useState(localStorage.getItem("token"));
  const [language, setLanguage] = React.useState("hu");
  const [occupation, setOccupation] = React.useState(0);
  const [search, setSearch] = React.useState({});
  const [productSearch, setProductSearch] = React.useState({});

  function getUsers() {
    return axios.post(`/user/search/${limit}/${skip}`, search)
      .then(response => {
        setUsers(() => [...response.data.users]);
        setUserCount(() => response.data.count);
      })
      .catch(console.error);
  }

  function getProducts() {
    return axios.post(`/products/search/${productLimit}/${productSkip}`, productSearch)
      .then(response => {
        setProducts(() => [...response.data.products]);
        setProductCount(() => response.data.count);
      })
      .catch(console.error);
  }

  function getOccupation() {
    return axios.get("/occupation")
      .then((result) => {
        setOccupation(() => result.data.current);
      })
      .catch(console.error);
  }

  React.useEffect(() => {
    getOccupation();
    getUsers();
  }, [search, skip, limit]);

  React.useEffect(() => {
    getProducts();
  }, [productSearch, productSkip, productLimit]);

  function logoutHandler() {
    localStorage.setItem("token", "");
    setToken("");
  }

  return (
    <div className="App">
      <Router>
        <Switch>
          { token && <Redirect from="/login" to="/users" /> }
          <Route exact path="/login">
            <Login token={token} setToken={setToken} />
          </Route>

          { !token && <Redirect to="/login" /> }
          <Route exact path="/users">
            <Users users={users} getUsers={getUsers} limit={limit} skip={skip} setSkip={setSkip} count={userCount} logoutHandler={logoutHandler} occupation={occupation} getOccupation={getOccupation} language={language} setSearch={setSearch} />
          </Route>
          <Route exact path="/products">
            <Products setProducts={setProducts} products={products} getProducts={getProducts} limit={productLimit} skip={productSkip} setSkip={setProductSkip} count={productCount} logoutHandler={logoutHandler} setSearch={setProductSearch} />
          </Route>
          <Route exact path="/logs">
            <UserLogs />
          </Route>
          <Route exact path="/occupation">
            <OccupationLogs />
          </Route>
          <Route exact path="/">
            <Redirect to="/users" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
